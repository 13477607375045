import React, { useCallback, useMemo, useState } from "react";
import {
  Login as BaseLogin,
  useLogin,
  useNotify,
  Notification,
} from "react-admin";
import { TextField, Button, CssBaseline } from "@mui/material";
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import errorMessageHandler from '../../Api/errorMessageHandler'
import useApi from "../../hooks/useApi";

import loginBck from '../../assets/images/login-swan.jpg'
import withTitle from "../with-title";

const InputsWrapper = styled.div`
  padding: 0 1em 1em 1em;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const SubmitWrapper = styled.div`
  padding: 0 1em 1em 1em;
`

const StyledLink = styled(Link)`
  display: inline-block;
  color: #1b1b1b;
  margin-bottom: 20px;
  font-size: 0.875rem;
`

const StyledForm = styled.form`
  text-align: center;
`

export const Login = withTitle(React.memo(() => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const login = useLogin()
  const notify = useNotify()
  const { api } = useApi();

  const isEmailLink = useMemo(() => {
    if (username.length && password.length === 0) {
      return true
    }
    return false
  }, [password, username])

  const submit = useCallback(
    (e) => {
      e.preventDefault()

      if (isEmailLink) {
        api.sendLoginLinkToEmail(username);
      } else {
        login({ username, password }).catch((e) => {
          notify(errorMessageHandler(e), 'error', {}, false, 100000)
        })
      }
    },
    [api, isEmailLink, login, notify, password, username]
  )

  let isFromWp = window.location.href.includes('from-wp=1')

  return (
    <BaseLogin backgroundImage={loginBck}>
      <CssBaseline />
      <StyledForm onSubmit={submit}>
        <InputsWrapper>
          {isFromWp && (
            <div>
              <p>
                <b>
                  Je probeerde in te loggen op <br /> swanmarket.nl
                </b>
              </p>
              <p>
                Het lijkt er op dat je creatieve maker <br />
                bent. Gebruik deze portal om je profiel <br />
                en producten te beheren. <br />
                <br />
                Log hieronder opnieuw in: <br />
              </p>
            </div>
          )}
          <div>
            <TextField
              id="username"
              label="Email address"
              style={{ width: '100%' }}
              value={username}
              required
              onChange={(event) => setUsername(event.target.value)}
              size="small"
            />
          </div>
          <div>
            <TextField
              id="password"
              label="Password"
              type="password"
              style={{ width: '100%' }}
              value={password}
              onChange={(event) => setPassword(event.target.value)}
              size="small"
            />
          </div>
        </InputsWrapper>

        <SubmitWrapper>
          <Button variant="contained" color="primary" type="submit" fullWidth>
            {isEmailLink ? 'Send link for login to E-mail' : 'Login'}
          </Button>
        </SubmitWrapper>

        <StyledLink to="/reset-password">Forgot your password?</StyledLink>
      </StyledForm>

      <Notification />
    </BaseLogin>
  )
}), 'Login')