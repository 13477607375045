import React, { useState, useEffect, useCallback } from "react";
import {
  Edit,
  SimpleForm,
  Toolbar,
  useRecordContext,
  TextField,
  useDataProvider,
  FunctionField,
  useRefresh,
  ArrayField,
  NumberField,
  Datagrid,
  SimpleList,
  SaveButton,
  useNotify,
  useRedirect
} from "react-admin";
import { Link } from 'react-router-dom';
import { Section, SectionInputs, SectionInfo } from "../section";
import withTitle from '../with-title'
import styled from "styled-components";
import { Checkbox, Button, Dialog, DialogTitle, DialogContent, IconButton, Grid, Paper, Tooltip, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddTrackForm from './AddTrackForm'
import { NumberInput } from "../app/OutlinedInputs";
import useApi from "../../hooks/useApi";
import { getApiRoute } from "../../config/routes";
import errorMessageHandler from "../../Api/errorMessageHandler";
import { formatInputTwoDecimals } from '../../utils/common'

const SvgWrapper = styled.div`
  width: 40px;
  height: 40px;
  svg {
    width: 100%;
    height: 100%;
  }
`;
const FieldWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  b {
    font-size: 14px;
  }
`;

const SectionTitle = styled.h3`
  margin: 0 0 10px 0;
`

const OrderEdit = ({ ...props }) => {
  const dataProvider = useDataProvider()
  const [creativeOptions, setCreativeOptions] = useState([])
  const [selectedLines, setSelectedLines] = useState([])
  const [shippers, setShippers] = useState([])
  const [addTrackDialog, setAddTrackDialog] = useState(false)
  const [order, setOrder] = useState({})
  const refresh = useRefresh();
  const isLgBreakpoint = useMediaQuery(theme => theme.breakpoints.down('lg'));
  const { api } = useApi();
  const notify = useNotify();
  const redirect = useRedirect();

  const fetchCreativeOptions = useCallback(async () => {
    try {
      const data = (
        await dataProvider.getList('creatives/select', {
          pagination: {},
          sort: { field: 'name', order: 'ASC' },
          filter: {},
        })
      ).data

      setCreativeOptions(data)
    } catch (e) {
      console.log(e)
    }
  }, [dataProvider])

  const fetchShippers = useCallback(async () => {
    try {
      const data = (
        await dataProvider.getList('order-shippers', {
          pagination: {},
          sort: { field: 'name', order: 'ASC' },
          filter: {},
        })
      ).data

      setShippers(data)
    } catch (e) {
      console.log(e)
    }
  }, [dataProvider])

  useEffect(() => {
    fetchCreativeOptions()
    fetchShippers()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onAddTrack = useCallback(() => {
    setAddTrackDialog(false)
    setSelectedLines([])
    refresh()
  }, [refresh])

  const PostEditToolbar = (props) => {
    const record = useRecordContext();
    if (Object.keys(order).length === 0) {
      setOrder(record)
    }

    return (
      <Toolbar {...props}>
        <div></div>
        <SaveButton />
        {/* {record?.deletedAt ? "" : <DeleteButton />} */}
      </Toolbar>
    );
  };

  const OrderTitle = () => {
    const record = useRecordContext()
    return <span>Edit order {record ? `"${record.id}"` : ''}</span>
  }

  const handleOpenAddTrackDialog = () => {
    setAddTrackDialog(true);
  };

  const handleCloseAddTrackDialog = () => {
    setAddTrackDialog(false);
  };

  const OpenDialogButton = ({ isCreative }) => {
    const record = useRecordContext();
    return (
      <Button disabled={!selectedLines.length || (isCreative && ['cancelled','refunded','trash','draft','completed'].includes(record.status.id))} sx={{ marginTop: '10px' }} variant="contained" onClick={handleOpenAddTrackDialog}>Add Tracking Code</Button>
    )
  }


  const onChangeSelectedLines = useCallback((e) => {
    if (e.target.checked) {
      setSelectedLines(prev => [...prev, e.target.value])
    } else {
      setSelectedLines(prev => prev.filter(line => line !== e.target.value))
    }
  }, [])

  const onClickLink = useCallback((event) => {
    event.stopPropagation();
  }, [])

  const save = async (values) => {
    await api.patch(getApiRoute(`orders/${values.id}`), { paymentCosts: values.payment.costs }).catch(error => notify(errorMessageHandler(error), "danger"))
    notify("Changes saved", "success");
    redirect("/orders");
  };

  const mobileTableCell = (record) => {
    const TrackTraceField = () => {
      if (!record.track) {
        return null
      }
      if (record.track.noTrackingCode) {
        return <span>No tracking code</span>
      }
      if (record.track.shipper?.custom) {
        return (
          <a href={record.track.link} target="_blank" rel="noopener noreferrer">
            {record.track.link}
          </a>
        )
      }
      if (!record.track.shipper?.custom) {
        return (
          <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <SvgWrapper dangerouslySetInnerHTML={{ __html: record.track.shipper.svg }}></SvgWrapper>
              <span>{record.track.number}</span>
            </div>
            <a href={record.track.link} target="_blank" rel="noopener noreferrer">
              {record.track.link}
            </a>
          </div>
        )
      }
    }

    return (
      <Paper elevation={3} style={{ display: 'flex', flexDirection: 'column', padding: '5px' }}>
        <div>
          { record.type === 'item' &&
            <Checkbox disabled={record.track || (order.status && ['pending'].includes(order.status.id))} value={record.id} checked={selectedLines.includes(record.id.toString())} onChange={onChangeSelectedLines}/>
          }
        </div>
        <div><b>Product:</b>
          { record.product ? 
            <Link to={`/products/${record.product.id}`} target="_blank" onClick={onClickLink}>
                {record.product.id}
            </Link>
            : 'N/A'
          }
        </div>
        <div><b>Name:</b> {record.name}</div>
        <div><b>Type:</b> {record.type}</div>
        {record.track && <div><b>Track & Trace:</b><TrackTraceField /></div>}
        
      </Paper>
    )
  }
  
  const roles = JSON.parse(localStorage.getItem('roles')) || []
  const isCreative = roles.includes('ROLE_CREATIVE')
  return (
    <Edit title={<OrderTitle />} {...props}>
      <SimpleForm
        toolbar={<PostEditToolbar />}
        onSubmit={save}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={4}>
            <Section style={{ flexDirection: 'column', border: 'none', height: '100%' }}>
              <Paper elevation={3} sx={{ padding: '16px', height: '100%' }}>
              <SectionTitle>Order Info</SectionTitle>
              <SectionInputs>
                <FieldWrapper>
                  <b>Number:</b>
                  <TextField source="number" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>Status:</b>
                  <TextField source="status.name" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>Customer Email:</b>
                  <TextField source="customer.email" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>Customer First Name:</b>
                  <TextField source="customer.firstName" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>Customer Last Name:</b>
                  <TextField source="customer.lastName" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>Payment method:</b>
                  <TextField source="payment.method" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>Payment costs (ex vat):</b>
                  <TextField source="payment.currency" />
                  <TextField source="payment.vatExcl" />
                  <Tooltip title="Payment costs are not always immediately known, for example different credit card companies charge different percentages. Payment costs are forwarded to you."><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" height="12"><path d="M48 80a48 48 0 1 1 96 0A48 48 0 1 1 48 80zM0 224c0-17.7 14.3-32 32-32l64 0c17.7 0 32 14.3 32 32l0 224 32 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 512c-17.7 0-32-14.3-32-32s14.3-32 32-32l32 0 0-192-32 0c-17.7 0-32-14.3-32-32z"/></svg></Tooltip>
                </FieldWrapper>
                <FieldWrapper>
                  <b>Note:</b>
                  <TextField source="note" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>Created At:</b>
                  <TextField source="createdAt.formatted3" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>Completed At:</b>
                  <TextField source="completedAt.formatted3" />
                  <Tooltip title="Orders are marked completed when all items of the order are shipped. Some items might not be displayed below, when they are for another creative."><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" height="12"><path d="M48 80a48 48 0 1 1 96 0A48 48 0 1 1 48 80zM0 224c0-17.7 14.3-32 32-32l64 0c17.7 0 32 14.3 32 32l0 224 32 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 512c-17.7 0-32-14.3-32-32s14.3-32 32-32l32 0 0-192-32 0c-17.7 0-32-14.3-32-32z"/></svg></Tooltip>
                </FieldWrapper>
                <FieldWrapper>
                  <b>Pay out at:</b> 
                  <FunctionField render={record => {
                    if (!record.completedAt) return ''
                    let d = new Date(record.completedAt.timestamp * 1000)
                    d.setMonth(d.getMonth() + 2)
                    d.setDate(0)
                    return d.toLocaleDateString('nl-NL', {day: 'numeric', month: 'long', year: 'numeric'})
                  }} />
                  <Tooltip title="Orders will be paid out the month after they are sent to the customer. The shipping can take up to a week, and then the customer has 2 weeks to notify us of the wish to return the item. We create invoices once a month to save on administration costs."><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512" height="12"><path d="M48 80a48 48 0 1 1 96 0A48 48 0 1 1 48 80zM0 224c0-17.7 14.3-32 32-32l64 0c17.7 0 32 14.3 32 32l0 224 32 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 512c-17.7 0-32-14.3-32-32s14.3-32 32-32l32 0 0-192-32 0c-17.7 0-32-14.3-32-32z"/></svg></Tooltip>
                </FieldWrapper>
              </SectionInputs>
              </Paper>
            </Section>
          </Grid>
          
          <Grid item xs={12} md={6} lg={4}>
            <Section style={{ flexDirection: 'column', border: 'none', height: '100%' }}>
              <Paper elevation={3} sx={{ padding: '16px', height: '100%' }}>
              <SectionTitle>Billing Info</SectionTitle>
              <SectionInputs>
                <FieldWrapper>
                  <b>Address:</b>
                  <TextField source="billing.address" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>City:</b>
                  <TextField source="billing.city" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>ZIP:</b>
                  <TextField source="billing.zip" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>Country:</b>
                  <TextField source="billing.country" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>Email:</b>
                  <TextField source="billing.email" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>First Name:</b>
                  <TextField source="billing.firstName" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>Last Name:</b>
                  <TextField source="billing.lastName" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>Company:</b>
                  <TextField source="billing.company" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>Phone:</b>
                  <TextField source="billing.phone" />
                </FieldWrapper>
              </SectionInputs>
              </Paper>
            </Section>
          </Grid>
          
          <Grid item xs={12} md={6} lg={4}>
            <Section style={{ flexDirection: 'column', border: 'none', height: '100%' }}>
              <Paper elevation={3} sx={{ padding: '16px', height: '100%' }}>
              <SectionTitle>Shipping Info</SectionTitle>
              <SectionInputs>
                <FieldWrapper>
                  <b>Address:</b>
                  <TextField source="shipping.address" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>City:</b>
                  <TextField source="shipping.city" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>ZIP:</b>
                  <TextField source="shipping.zip" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>Country:</b>
                  <TextField source="shipping.country" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>First Name:</b>
                  <TextField source="shipping.firstName" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>Last Name:</b>
                  <TextField source="shipping.lastName" />
                </FieldWrapper>
                <FieldWrapper>
                  <b>Company:</b>
                  <TextField source="shipping.company" />
                </FieldWrapper>
              </SectionInputs>
              </Paper>
            </Section>
          </Grid>
          <Grid item xs={12}>
            <Section style={{ flexDirection: 'column' }}>
              <SectionInfo title={'Lines'} />
              <div>
                <OpenDialogButton lines={selectedLines} isCreative={isCreative} />
              </div>
              <ArrayField source="lines">
                {isLgBreakpoint ? (
                  <SimpleList
                    linkType={false}
                    primaryText={record => ''}
                    secondaryText={mobileTableCell}
                  />
                ) : (
                <Datagrid bulkActionButtons={false}>
                  <FunctionField label="" render={record => 
                    record.type === 'item' &&
                      <Checkbox disabled={record.track || (order.status && ['pending'].includes(order.status.id))} value={record.id} checked={selectedLines.includes(record.id.toString())} onChange={onChangeSelectedLines}/>
                    }
                  />
                  <FunctionField label="Product" render={record => 
                      record.product ? 
                      <Link to={`/products/${record.product.id}`} target="_blank" onClick={onClickLink}>
                          {record.product.id}
                      </Link>
                      : 'N/A'}
                  />
                  <TextField source="name" label="Name" />
                  <TextField source="type" label="Type" />
                  <FunctionField label="Track & Trace" render={record => {
                    if (!record.track) {
                      return null
                    }
                    if (record.track.noTrackingCode) {
                      return <span>No tracking code</span>
                    }
                    if (record.track.shipper?.custom) {
                      return (
                        <a href={record.track.link} target="_blank" rel="noopener noreferrer">
                          {record.track.link}
                        </a>
                      )
                    }
                    if (!record.track.shipper?.custom) {
                      return (
                        <div>
                          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <SvgWrapper dangerouslySetInnerHTML={{ __html: record.track.shipper.svg }}></SvgWrapper>
                            <span>{record.track.number}</span>
                          </div>
                          <a href={record.track.link} target="_blank" rel="noopener noreferrer">
                            {record.track.link}
                          </a>
                        </div>
                      )
                    }
                  }}
                  />
                  <NumberField source="price.qty" label="Quantity" />
                  <NumberField source="price.unit" label="Unit Price" />
                  <NumberField source="price.total" label="Total" />
                </Datagrid>
                )}
              </ArrayField>
            </Section>
            {!isCreative &&
            <Section style={{ flexDirection: 'column' }}>
                <NumberInput
                  source="payment.costs"
                  label="Payment costs"
                  type="text"
                  format={formatInputTwoDecimals}
                  parse={(v) => v}
                />
            </Section>
            }
          </Grid>
        </Grid>
        <Dialog onClose={handleCloseAddTrackDialog} open={addTrackDialog} maxWidth="xl">
          <DialogTitle>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            Add Tracking Code
            <IconButton
                edge="start"
                color="inherit"
                onClick={handleCloseAddTrackDialog}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
          </div>
          </DialogTitle>
          <DialogContent sx={{ maxWidth: '550px' }}>
            <AddTrackForm lines={selectedLines} creativeOptions={creativeOptions} shippers={shippers} onAdd={onAddTrack} />
          </DialogContent>
        </Dialog>
      </SimpleForm>
    </Edit>
  )
};

export default withTitle(OrderEdit, 'Order Edit');
